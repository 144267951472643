import request from '@/utils/request'
// 订单管理
export function orderList(p, ps, data) {
    return request({
      url: `/orders/page/${p}/${ps}`,
      method: 'post',
      data
    });
}

// 获取订单详情
export function orderDetail(id, params) {
    return request({
      url: `/orders/${id}`,
      method: 'GET',
      params
    });
}
// 修改订单详情
export function updateOrderDetail( data) {
    return request({
      url: '/orders',
      method: 'post',
      data
    });
}
// 删除订单
export function deleteOrder( data) {
  return request({
    url: '/orders',
    method: 'delete',
    data
  });
}